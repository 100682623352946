import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import "./JfwFeedBack.css"; // Import the CSS file
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import {jfw_add_feedback} from '../../api/api';
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import config from "../../aws-exports";
 function JfwFeedBack() {
    let userDetails = useSelector(authData);
    const history = useHistory();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [jfwIndividual,setJfwIndividual] =useState(0)
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    mainSection: {
      padding: "2rem!important",
      "@media (max-width: 450px)": {
        padding: "1rem!important",
      },
    },
    loaderSkeletonTabs: {
      width: "90vw",
      height: "50rem",
    },
    LPMainContainer: {
      width: "100%",
      borderRadius: "0.5rem",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 10px 0 hsla(0,0%,40%,.3607843137)",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#292F3B !important"
          : theme.palette.background.paper,
    },
    paper: {
      paddingBottom: "2rem",
      marginBottom: "2rem",
      boxShadow:
        "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%)!important",
    },
    buttonSection: {
      textAlign: "center",
      margin: "1rem",
      width: "100%",
    },
    button: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      fontFamily: `myfontregular!important`,
    },
    tabButton: {
      textTransform: "capitalize!important",
      fontSize: "2rem!important",
      paddingTop: "0!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "800!important",
      alignItems: "start!important",
      paddingLeft: "5rem!important",
      textAlign: "left!important",
    },
    tabIcon: {
      width: "3rem",
      position: "relative",
      left: "-35px",
      top: "27px",
      margin: "0!important",
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#272C38 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#272C38 !important",
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#272C38 !important",
        },
      },
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
    error: {
        color: "red",
        fontSize: "0.8rem",
      }
  }));
  const classes = useStyles();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["productData"],
    queryFn: get_jfwfeedbackrepors,
    onSuccess: (data) => {
      if (data) {
        setJfwIndividual(data.length);
      }
    },
  });
  async function get_jfwfeedbackrepors() {
    try {
      const requestBody = {
        schema: config.schema,
        ur_id: userDetails.uData.ur_id,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/get_jfwfeedbackreports?json=${encodeURIComponent(base64EncodedString)}`;
      const res = await API.get(config.aws_cloud_logic_custom_name, reportInfo, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      });
      return res.reports;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  const handleFeedBackSubmit = async(e) => {
    e.preventDefault();
    const newErrors = {};
    // Validate required fields
   // Validate required fields (with trimming)
  if (!formData.jfwdate || !formData.jfwdate.trim()) newErrors.jfwdate = "JFW date is mandatory.";
  if (!formData.Purpose || !formData.Purpose.trim()) newErrors.Purpose = "Purpose/Objective is mandatory.";
  if (!formData.customer_name || !formData.customer_name.trim()) newErrors.customer_name = "Customer Name is mandatory.";
  if (!formData.emp_id || !formData.emp_id.trim()) newErrors.emp_id = "Employee ID is mandatory.";
  if (!formData.call_status || !formData.call_status.trim()) newErrors.call_status = "Status of Call is mandatory.";
  if (!formData.hymsdc || !formData.hymsdc.trim()) newErrors.hymsdc = "Support description is mandatory.";
  if (!formData.wpymbc || !formData.wpymbc.trim()) newErrors.wpymbc = "Preparation details are mandatory.";
  if (!formData.ppdc || !formData.ppdc.trim()) newErrors.ppdc = "Product Pitched details are mandatory.";
  if (!formData.wcyhdbc || !formData.wcyhdbc.trim()) newErrors.wcyhdbc = "Improvement suggestions are mandatory.";
  if (!formData.jfw_learnings || !formData.jfw_learnings.trim()) newErrors.jfw_learnings = "Learnings from JFW are mandatory.";
    // If there are errors, set the error state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; 
    }
    let requestData = {...formData};
    requestData.ur_id = userDetails.uData.ur_id;
    let result = await jfw_add_feedback(requestData,userDetails)
    if(result.statusCode === 200){
        Swal.fire({
            title: "Good job!",
            text: "You submitted the form!",
            icon: "success"
          }).then((result) => {
        if (result.isConfirmed) {
              history.push("/home");
          }
      });
    }
    setFormData({});
    setErrors({});
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.LPMainContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "2rem",
            }}
          >
            <h2>JFW Feedback Form</h2>
            <div className="circle">
              <span>{jfwIndividual}</span>
            </div>
            <p className="subtitle">
                Forms submitted this month</p>
          </div>
          <div>
            <form style={{ padding: "1.5rem" }}>
            <div className="jfwfields_one" >
            <InfoOutlinedIcon sx={{ color: "#1e90ff" }}/>
           <p style={{fontSize: "1rem",fontWeight:"bold"}}>All the fields are mandatory to fill</p>
            </div> 
              <div className="jfwfields">
                <label>Jfw date<sup>*</sup></label>
                <input value={formData.jfwdate}
                 required 
                  type="date"
                  onChange={(event) => {
                    setFormData((previousState) => ({ ...previousState, jfwdate: event.target.value }));
                    if (event.target.value) setErrors((prev) => ({ ...prev, jfwdate: undefined }));
                  }}
                />
                 {errors.jfwdate && <div className={classes.error}>{errors.jfwdate}</div>}
              </div>
              <div className="jfwfields">
                <label>Purpose/Objective<sup>*</sup></label>
                <input value={formData.Purpose} required onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, Purpose: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, Purpose: undefined }));
                }}
              />
              {errors.Purpose && <div className={classes.error}>{errors.Purpose}</div>}
              </div>
              <div className="jfwfields">
                <label>Customer Name<sup>*</sup></label>
                <input required value={formData.customer_name} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, customer_name: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, customer_name: undefined }));
                }}
              />
              {errors.customer_name && <div className={classes.error}>{errors.customer_name}</div>}
              </div>
              <div className="jfwfields">
                <label>Accompanied by (Mention the Emp ID)<sup>*</sup></label>
                <input required value={formData.emp_id} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, emp_id: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, emp_id: undefined }));
                }}
              />
              {errors.emp_id && <div className={classes.error}>{errors.emp_id}</div>}
              </div>
              <div className="jfwfields">
                <label>Status of Call<sup>*</sup></label>
                <input required value={formData.call_status} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, call_status: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, call_status: undefined }));
                }}
              />
              {errors.call_status && <div className={classes.error}>{errors.call_status}</div>}
              </div>
              <div className="jfwfields">
                <label>
                  Describe how your manager supported you during your call<sup>*</sup>
                </label>
                <textarea required  value={formData.hymsdc} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, hymsdc: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, hymsdc: undefined }));
                }}
              />
              {errors.hymsdc && <div className={classes.error}>{errors.hymsdc}</div>}
              </div>
              <div className="jfwfields">
                <label>What preparations did you make before the call?<sup>*</sup></label>
                <textarea required value={formData.wpymbc} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, wpymbc: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, wpymbc: undefined }));
                }}
              />
              {errors.wpymbc && <div className={classes.error}>{errors.wpymbc}</div>}
              </div>
              <div className="jfwfields">
                <label>Product Pitched during the call<sup>*</sup></label>
                <textarea required  value={formData.ppdc} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, ppdc: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, ppdc: undefined }));
                }}
              />
              {errors.ppdc && <div className={classes.error}>{errors.ppdc}</div>}
              </div>
              <div className="jfwfields">
                <label>What could you have done better in the call?<sup>*</sup></label>
                <textarea required value={formData.wcyhdbc} onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, wcyhdbc: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, wcyhdbc: undefined }));
                }}
              />
              {errors.wcyhdbc && <div className={classes.error}>{errors.wcyhdbc}</div>}
              </div>
              <div className="jfwfields">
                <label>Mention your top 3 learnings from JFW<sup>*</sup></label>
                <textarea required value={formData.jfw_learnings}  onChange={(event) => {
                  setFormData((previousState) => ({ ...previousState, jfw_learnings: event.target.value }));
                  if (event.target.value) setErrors((prev) => ({ ...prev, jfw_learnings: undefined }));
                }}
              />
              {errors.jfw_learnings && <div className={classes.error}>{errors.jfw_learnings}</div>}
              </div>
              <div className="jfwfields" >
                <button className="jfwfields_button" onClick={handleFeedBackSubmit}>
                    <p>Submit</p> <SendIcon sx={{color:"white"}}/>
                </button>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}
export default JfwFeedBack;
