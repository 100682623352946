/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
export const Theme = {
  color: {
    // primary: " #217FB5",
    primary: " #217BB5",
    secondary: "#1C6D9C",
    appGray: "#7c7f91",
    secondaryLight: "rgba(40, 87, 129, 0.2)",
    grey_light_1: "#f7f7f7",
    grey_light_2: "#eee",
    grey_dark: "#777",
    grey_dark_2: "#999",
    grey_dark_3: "#333",
    white: "#fff",
    black: "#000",
  },
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
  backDropOpacity: "rgba(0, 0, 0, 0.85)",
};
const excelearnProd = {
  schema: "enabledsm",
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "us-east-1:b3f3d2c7-4164-483a-80fb-dd0a657676f8",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_dscvgAL0P",
  aws_user_pools_web_client_id: "tibqtgrc5bfnq81bj0n25lfki",
  oauth: {},
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "EXCELEARN",
  aws_cloudfront_url: "https://www.excelearn.enabled.live/",
  aws_cloudfront_lurl: "https://www.excelearn.enabled.live",
  DOMAIN: "www.excelearn.enabled.live",
  aws_api_name: "EN-PLATFORM",
  aws_api_endpoint:
    "https://kqd29za2e4.execute-api.ap-south-1.amazonaws.com/EN48576",
  aws_cloud_logic_custom_name: "EN-PLATFORM",
  aws_cloud_logic_custom: [
    {
      id: "kqd29za2e4",
      name: "EN-PLATFORM",
      description: "",
      endpoint:
        "https://kqd29za2e4.execute-api.ap-south-1.amazonaws.com/EN48576",
      region: "ap-south-1",
    },
  ],
  auth_url: "https://excelearn.auth.ap-south-1.amazoncognito.com/",
  appVersion: "2.3.3",
  secretKey:"beforedsfdghh232!%@&#%#)#*8364251",
  secretKeyAfter:"lkewr!%@&#%#)#*49327493284"
};
const nseDev = {
  schema: "nse",
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_KgKOC6o5l",
  aws_user_pools_web_client_id: "5u1k2qqpvpnuuqkr22hjlijsjb",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "NSE",
  aws_cloudfront_url: "https://d13trvs3ngcag9.cloudfront.net",
  aws_cloudfront_lurl: "https://d13trvs3ngcag9.cloudfront.net",
  DOMAIN: "https://d13trvs3ngcag9.cloudfront.net",
  aws_api_name: "nseregistrationapi",
  aws_api_endpoint:
    "https://06cbde50ti.execute-api.ap-south-1.amazonaws.com/dev",
  aws_cloud_logic_custom_name: "nseregistrationapi",
  aws_cloud_logic_custom: [
    {
      id: "06cbde50ti",
      name: "nseregistrationapi",
      description: "",
      endpoint: "https://06cbde50ti.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
  ],
  // auth_url: "https://axiata.auth.ap-south-1.amazoncognito.com/",
};
const excelearndev = {
  aws_project_region: "ap-south-1",
  schema: "enabled",
  // aws_cognito_identity_pool_id:
  //   "us-east-1:b9d3a0f2-ad3c-41da-93eb-9ea021ff9397",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_3KWRq9cAd",
  aws_user_pools_web_client_id: "4kf26chr0acga63b7bjade31mm",
  oauth: {},
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "ENABLED",
  aws_cloudfront_url: "d177q4h9hpq2tk.cloudfront.net",
  aws_cloudfront_lurl: "d177q4h9hpq2tk.cloudfront.net",
  aws_content_delivery_cloudfront_domain: "d177q4h9hpq2tk.cloudfront.net",
  DOMAIN: "d177q4h9hpq2tk.cloudfront.net",
  aws_api_name: "EN-PLATFORM",
  aws_api_endpoint:
    "https://n9mw2pie0m.execute-api.ap-south-1.amazonaws.com/ED-4041",
  aws_cloud_logic_custom_name: "EN-PLATFORM",
  aws_cloud_logic_custom: [
    {
      id: "n9mw2pie0m",
      name: "EN-PLATFORM",
      description: "",
      endpoint:
        "https://n9mw2pie0m.execute-api.ap-south-1.amazonaws.com/ED-4041",
      region: "ap-south-1",
    },
  ],
  auth_url: "https://devexcelsoft.auth.us-east-1.amazoncognito.com/",
  secretKey:"before3872948732!%@&#%#)#*49327493284",
  secretKeyAfter:"sdhfkjhs3872948732!%@&#%#)#*49327493284"
};
const staging = {
  main_color_1: "#093055",
  main_color_2: "#f18121",
  platform_main_theme: "linear-gradient(#093055, #093055)",
  button_color_web: "#0f80a4",
  SCHEMA_NAME: "excelsoft",
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "us-east-1:b3f3d2c7-4164-483a-80fb-dd0a657676f8",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_Jh4UgtpAR",
  aws_user_pools_web_client_id: "1a455hi59ucka57qbn38ml5l4i",
  oauth: {},
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "ENABLED",
  learningpath: false,
  liveSession: false,
  mt_id: 1,
  aws_cloudfront_url: "https://www.staging.enabled.live/",
  aws_cloudfront_lurl: "https://www.staging.enabled.live",
  DOMAIN: "www.staging.enabled.live",
  aws_api_name: "EN-PLATFORM",
  aws_api_endpoint:
    "https://qb0lbovfw9.execute-api.ap-south-1.amazonaws.com/StagingProd",
  aws_cloud_logic_custom_name: "EN-PLATFORM",
  aws_cloud_logic_custom: [
    {
      id: "qb0lbovfw9",
      name: "EN-PLATFORM",
      description: "",
      endpoint:
        "https://qb0lbovfw9.execute-api.ap-south-1.amazonaws.com/StagingProd",
      region: "ap-south-1",
    },
  ],
  appVersion: 2.3,
  secretKey:"beforestagigng48732!%@&#%#)#*49327493284",
  secretKeyAfter:"stagjhs3872948732!%@&#%#)#*49327493284"
};
export default excelearndev;
