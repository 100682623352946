import JfwFeedBack from "../../components/JfwFeedBack/JfwFeedBack";
import {
    Stack,
    Typography,
    Breadcrumbs,
  } from "@mui/material";
  import { Link } from "react-router-dom";
  import { useSelector } from "react-redux";
  import { authData } from "../../redux/auth/authSlice";
  import { makeStyles } from "@material-ui/core/styles";
  import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const JswFormpage = () => {
    const userDetails = useSelector(authData);
    const useStyles = makeStyles((theme) => ({
        mainContainer: {
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          "@media (max-width: 550px)": {
            marginBottom: "1rem",
          },
        },
        loaderSkeleton: {
          width: "200px",
          height: "3rem",
          "@media (max-width: 750px)": {
            width: "200px",
          },
        },
        button: {
          backgroundColor: "var(--color-button-background)!important",
          fontSize: "1.5rem!important",
          textTransform: "capitalize!important",
          fontFamily: `myfontregular!important`,
        },
        breadcrumbNameStyle: {
          fontSize: "1.5rem!important",
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "black !important",
          fontFamily: `myfontregular!important`,
        },
      }));
    const classes = useStyles();
    const breadcrumbs = [
        <Link
          underline="hover"
          key="1"
          color="inherit"
          to={"/profile"}
          style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
        >
          {`${userDetails?.languagejson?.profile || "Profile"}`}
        </Link>,
        <Typography
          key="3"
          color="text.primary"
          className={classes.breadcrumbNameStyle}
        >
          {`${userDetails?.languagejson?.jfw_feedback_form || "JFW feedback form"}`}
        </Typography>,
      ];
  return (
    <>
    <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="large" style={{color:"var(--color-primary)"}} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="formpage" 
  style={{display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"1rem"}}>
    <div style={{width:"50%"}}>
    <JfwFeedBack/>
    </div>
  </div>
    </>
  );
};
export default JswFormpage;